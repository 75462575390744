.splashRoot {
  color: white;
  font-size: 1.9rem;
  font-weight: 300;
  .links {
    a {
      color: inherit;
      margin-right: 1rem;
      font-size: 3rem;
    }
  }
}
