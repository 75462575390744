.headerRoot {
  position: fixed;
  top: 0;
  width: 100%;
  height: 7rem;
  margin: 0rem 0 1rem 0;
  display: flex;
  align-items: center;
  background: linear-gradient(to right, #1f262d, #343b42);
  .navList {
    margin-left: 10vh;
    margin-right: 10vh;
    position: relative;
    display: flex;
    align-items: center;
    font-size: 2.2rem;
    @media only screen and (max-width: 450px) {
      font-size: 1.8rem;
      white-space: nowrap;
    }
    color: white;
    font-weight: 600;
    .logo {
      width: 5rem;
      height: 5rem;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      img {
        max-width: 70%;
        max-height: 70%;
      }
    }
  }
}
